import React, { Component } from "react";
import blog_push from "../media/blog_push380.png";
import arrow from "../media/arrow.svg";
import "./Blog.css";

export default class Blog extends Component {
  render() {
    return (
      <div className="blog-block">
        <h1>Blog</h1>
        <div className="blog-block-content">
          <div className="blog-element">
            <div
              className="image"
              style={{ backgroundImage: `url(${blog_push})` }}
            />
            <div className="reading-time">5 min</div>
            <div className="article-name">
              Understanding Push Ads: Their Mechanics and Impact
            </div>
            <div className="article-description">
              Push ads seamlessly blend into user interfaces, offering a
              discrete yet prominent advertising solution. They excel in
              affiliate marketing due to their flexibility and urgency creation,
              prompting immediate user action.
            </div>
            <div className="article-date">November 2023</div>
            <div className="read-more">
              <a href="/blog/push-ads">
                Read more
                <img alt="→" src={arrow} />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
