import React from "react";
import "./FirstAnimBlock.css";
import RoundedButton from "../RoundedButton/RoundedButton";
import animation from "../media/animation.gif";

function FirstAnimBlock(props) {
  return (
    <div className="first-anim-block">
      <div className="left-block">
        <div className="text-perfect-partner">
          <p>
            <text>Com2Cloud</text> - your perfect partner in developing business
          </p>
        </div>
        <div className="make-project-button">
          <RoundedButton>make your project com 2 life</RoundedButton>
        </div>
      </div>
      <img alt="animation" src={animation} className="right-block"></img>
    </div>
  );
}

export default FirstAnimBlock;
