import React, { Component } from "react";
import "./Services.css";
import RoundedButton from "../RoundedButton/RoundedButton";
import ServiceItems from "./ServicesItems/ServiceItems";

export default class Services extends Component {
  render() {
    return (
      <div className="services" id="services">
        <h1>Services</h1>
        <div className="services-content">
          <div className="services-learn">
            <div>
              Com2Cloud provides a wide range of services supporting the all
              round growth of your business.
            </div>
            <RoundedButton>Learn More</RoundedButton>
          </div>
          <div className="services-scroller">
            <ServiceItems />
          </div>
        </div>
      </div>
    );
  }
}
