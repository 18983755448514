import React, { Component } from "react";
import github from "../media/github.svg";
import facebook from "../media/facebook.svg";
import twitter from "../media/twitter.svg";
import linkedin from "../media/linkedin.svg";
import "../RoundedButton/RoundedButton.css";
import "./ContactUs.css";

export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      secondName: "",
      email: "",
      message: "",
      messageSent: false, // New state variable for tracking success
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      firstName: this.state.firstName,
      secondName: this.state.secondName,
      email: this.state.email,
      message: this.state.message,
    };

    try {
      await fetch("https://webhook.site/eb7fa4d4-6b76-46a2-8397-7d7fa788f33c", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        mode: "no-cors",
      });
      this.setState({ messageSent: true });
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle any network or other errors here.
    }
  };

  render() {
    return (
      <div id="contact" className="contact">
        <h1>Contact Us</h1>
        <div className="contact-content">
          <div className="contact-info">
            <div className="text">
              Interested in working together? We’d love to hear from you.
            </div>
            <div className="info">
              <b>Any questions?</b>
              <a href="email:sales@com2cloud.com" className="email">
                sales@com2cloud.com
              </a>
              <div className="socials">
                <a href="https://github.com/com2cloud">
                  <img alt="GitHub" src={github} />
                </a>
                <a href="https://facebook.com/">
                  <img alt="Facebook" src={facebook} />
                </a>
                <a href="https://twitter.com/com2cloud">
                  <img alt="Twitter" src={twitter} />
                </a>
                <a href="https://www.linkedin.com/company/com2cloud/">
                  <img alt="LinkedIn" src={linkedin} />
                </a>
              </div>
            </div>
          </div>
          <div className="contact-form">
            <form
              // unvavailable from this.state.messageSent
              style={{
                cursor: this.state.messageSent ? "not-allowed" : "pointer",
              }}
              onSubmit={this.handleSubmit}
            >
              <div className="two-columns">
                <div className="form-input-container">
                  <div className="form-input-header">First Name</div>
                  <input
                    autoComplete="given-name"
                    placeholder="First Name"
                    id="first-name"
                    className="form-input"
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.handleInputChange}
                    disabled={this.state.messageSent}
                  />
                </div>
                <div className="form-input-container">
                  <div className="form-input-header">Second Name</div>
                  <input
                    autoComplete="family-name"
                    placeholder="Second Name"
                    id="second-name"
                    className="form-input"
                    name="secondName"
                    value={this.state.secondName}
                    onChange={this.handleInputChange}
                    disabled={this.state.messageSent}
                  />
                </div>
              </div>
              <div className="form-input-container">
                <div className="form-input-header">Email</div>
                <input
                  autoComplete="on"
                  autoCompleteType="email"
                  required
                  type="email"
                  placeholder="Email"
                  id="email"
                  className="form-input"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  disabled={this.state.messageSent}
                />
              </div>

              <div className="form-input-container">
                <div className="form-input-header">Message</div>
                <textarea
                  placeholder="Message"
                  id="message"
                  className="form-input"
                  name="message"
                  value={this.state.message}
                  onChange={this.handleInputChange}
                  disabled={this.state.messageSent}
                />
              </div>
              <button
                style={{
                  cursor: this.state.messageSent ? "not-allowed" : "pointer",
                }}
                type="submit"
                className="rounded-button"
                disabled={this.state.messageSent}
              >
                {this.state.messageSent
                  ? "Message sent succesfully!"
                  : "Send Message"}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
