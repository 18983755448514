import React from "react";

import "./Blogs.css";
import "../Home.css";
import Blog from "../HomeContent/Blog";

function MainBlog(props) {
  return (
    <div className="landing">
      <div className="landing-content" style={{ paddingBottom: "200px" }}>
        <Blog></Blog>
      </div>
    </div>
  );
}

export default MainBlog;
