import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import "./FuturaFont/stylesheet.css";
import Home from "./Home";
import PushAds from "./Blog/PushAds";
import MainBlog from "./Blog/MainBlog";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Advertisers from "./Advertisers/Advertisers";
import Publishers from "./Publishers/Publishers";

ReactDOM.render(
  <div className="body">
    <Header />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog/push-ads" element={<PushAds />} />
        <Route path="/blog" element={<MainBlog />} />
        <Route path="/advertisers" element={<Advertisers />} />
        <Route path="/publishers" element={<Publishers />} />
      </Routes>
    </BrowserRouter>
    <Footer />
  </div>,
  document.getElementById("root")
);
