import React from "react";
import "./Advertisers.scss";
import banner from "../media/banner.png";
import popunderImage from "../media/popunder.png";
import interstitialAdImage from "../media/interstitial.png";
import backbuttonAdImage from "../media/backbutton.png";
import fancyImage from "../media/fancy.png";
import tabImage from "../media/tab.png"

function Advertisers(props) {
    return (
        <div className="landing">
            <div className="landing-content">
                <div className="advertisers-container">
                    <h1>Advertisers</h1>
                    <div className="intro-content">

                        <img src={fancyImage} alt="Fancy representation of our services" />
                        <div className="intro-left">
                            <p>Welcome to our advertising solutions platform, where we offer a wide array of ad formats tailored to meet your marketing goals. Whether you're looking to drive traffic, increase conversions, or engage users, we have the perfect solution for you. Explore the diverse ad formats we offer and discover how they can help you reach your target audience effectively.</p>
                            <p>Our team of experts is here to guide you through every step, ensuring that your campaigns are optimized for maximum impact.</p>
                        </div>
                    </div>
                    <h2>Our ad formats</h2>
                    <h3>Banner</h3>
                    <div className="adFormat-block">
                        <div className="left-side">
                            <img src={banner} />
                        </div>
                        <div className="right-side">
                            <p>Banner ads are visual advertisements displayed across websites, mobile apps, or social media platforms, making them a highly sought-after traffic source for affiliate marketers. These ads come in a variety of sizes and formats, including static images, animated GIFs, and rich media, and can be tailored for both desktop and mobile devices. Common banner sizes include 300x100, 300x250, and 300x600. When placed strategically on webpages, these banners are designed to grab the user's attention and motivate them to take a specific action, such as clicking to visit the advertiser's landing page.</p>
                            <ul>
                                <li><b>Visual advertisements</b> that appear on websites, apps, or social media platforms.</li>
                                <li>Available in <b>various formats</b>, including static images, animated GIFs, and rich media.</li>
                                <li><b>Adaptable</b> for both mobile and desktop viewing.</li>
                                <li>Placed <b>strategically</b> on webpages to capture user attention.</li>
                            </ul>
                        </div>
                    </div>
                    <h3>Popunder</h3>
                    <div className="adFormat-block">
                        <div className="left-side">
                            <p>Popunder ads are a popular form of online advertising that appears in a new browser window or tab, usually behind the current one. These ads are effective for affiliate marketers because they ensure the user sees the ad when they close or minimize their main window. Popunder ads can be tailored for both desktop and mobile users, making them versatile in reaching a wide audience.</p>
                            <ul>
                                <li><b>Popunder ads</b> open in a new browser window or tab, typically behind the current one.</li>
                                <li>These ads are <b>less intrusive</b> and appear after the main window is closed or minimized.</li>
                                <li><b>Effective</b> for reaching both desktop and mobile users.</li>
                                <li>Designed to <b>capture attention</b> when the user finishes their initial activity.</li>
                            </ul>
                        </div>
                        <div className="right-side">
                            <img src={popunderImage} />
                        </div>
                    </div>
                    <h3>Backbutton</h3>
                    <div className="adFormat-block">
                        <div className="left-side">
                            <img src={backbuttonAdImage} />
                        </div>
                        <div className="right-side">
                            <p>Back button ads are a unique form of online advertising that trigger when a user attempts to navigate away from a webpage by clicking the back button. These ads effectively intercept the user's action, displaying a targeted advertisement instead of taking them to the previous page. In affiliate marketing, back button ads are highly effective for capturing the attention of users who may be leaving a site, offering a last opportunity to engage them with relevant content or offers on both desktop and mobile platforms. This ad format is particularly powerful in retaining user engagement and driving conversions.</p>
                            <ul>
                                <li><b>Triggered ads</b> that appear when a user clicks the back button on a webpage.</li>
                                <li>Intercepts the user's action, displaying a <b>targeted advertisement</b> instead of returning to the previous page.</li>
                                <li>Highly effective in <b>retaining user engagement</b> on both desktop and mobile platforms.</li>
                                <li>Provides a last opportunity to <b>capture user attention</b> and drive conversions.</li>
                            </ul>
                        </div>
                    </div>
                    <h3>Interstitial</h3>
                    <div className="adFormat-block">
                        <div className="left-side">
                            <p>Interstitial ads are full-screen advertisements that appear during natural transition points within an app or website. These ads are strategically placed between user interactions, such as during page transitions or app launches. In affiliate marketing, interstitial ads have gained popularity due to their immersive and interactive nature, making them highly effective in capturing the attention of users on both desktop and mobile platforms. With their engaging visuals and interactive content, interstitial ads are designed to leave a lasting impression and encourage users to take specific actions.</p>
                            <ul>
                                <li><b>Full-screen ads</b> that appear during natural transition points in apps or websites.</li>
                                <li>Strategically placed between <b>user interactions</b>, like page transitions or app launches.</li>
                                <li><b>Immersive and interactive</b> format effective on both desktop and mobile platforms.</li>
                                <li>Designed to <b>engage users</b> with captivating visuals and interactive content.</li>
                            </ul>
                        </div>
                        <div className="right-side">
                            <img src={interstitialAdImage} />
                        </div>
                    </div>
                    <h3>Tab</h3>
                    <div className="adFormat-block">
                        <div className="left-side">
                            <img src={tabImage} />
                        </div>
                        <div className="right-side">
                            <p>Tab ads are advertisements that open in a new browser tab, when user presses tab on website. These ads offer a non-intrusive way to engage users without interrupting their current browsing experience. In affiliate marketing, tab ads are an effective tool for capturing user attention by subtly opening new tabs that display targeted advertisements. This ad format is particularly useful for driving traffic to specific offers while allowing users to continue their initial activity.</p>
                            <ul>
                                <li><b>Opens in a new tab</b>, typically triggered by a user interaction on a webpage.</li>
                                <li>Provides a <b>non-intrusive</b> way to engage users without disrupting their browsing experience.</li>
                                <li>Effective for <b>driving traffic</b> to targeted offers in affiliate marketing.</li>
                                <li>Allows users to <b>continue their activity</b> while presenting additional content.</li>
                            </ul>
                        </div>
                    </div>
                    <p>Your ads are only a few steps away from the audience you want to reach.</p>
                    <p>Our experienced managers, advanced targeting options, and highly-trafficked Publisher sites are a winning combination that will help you run a successful marketing campaign for your online dating business.</p>
                    <p>For inquiries, please send an email to <a href="mailto:advertisers@com2cloud.com">advertisers@com2cloud.com</a>.</p>
                </div>
            </div>
        </div>
    );
}

export default Advertisers;
