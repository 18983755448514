import React, { Component } from "react";
import "./Partners.css";
import gcpImage from "../media/gcp.png";
import lambdaImage from "../media/lambda.png";
import awsImage from "../media/aws.png";

export default class Partners extends Component {
  render() {
    return (
      <div className="partners">
        <h1>Our Partners</h1>
        <div className="partners-content">
          <img src={gcpImage} alt="Google Cloud Platform" />
          <img src={awsImage} alt="Amazon Web Services" />
          <img src={lambdaImage} style={{ height: "200px" }} alt="AWS Lambda" />
        </div>
      </div>
    );
  }
}
