import React from "react";
import "./Publishers.scss";
import adFormatsImage from "../media/fancy.png";
import supportImage from "../media/support.svg";
import trafficImage from "../media/traffic.png";
import oneTagImage from "../media/oneTagImage.png";

function Publishers(props) {
    return (
        <div className="landing">
            <div className="landing-content">
                <div className="publishers-container">
                    <h1>Publishers</h1>
                    <div className="intro-content">
                        <img src={adFormatsImage} alt="Ad formats overview" />
                        <div className="intro-left">
                            <p>Welcome to Com2Cloud's publisher solutions platform, where we offer a fully customizable ad campaign experience. Whether you're looking to maximize your ad revenue or streamline your ad management, our bespoke solutions are designed to meet your needs. Place just one tag and unlock a wide range of ad formats to start monetizing your site effectively.</p>
                            <p>Our dedicated team is available to assist you through every step, ensuring your setup is optimized for success. For full details and setup assistance, contact us at <a href="mailto:publishers@com2cloud.com">publishers@com2cloud.com</a>.</p>
                        </div>
                    </div>

                    <h2>Why Choose Us?</h2>

                    <h3>Live Support</h3>
                    <div className="advantage-block">
                        <div className="left-side">
                            <img src={supportImage} alt="Live support" />
                        </div>
                        <div className="right-side">
                            <p>At Com2Cloud, we believe in providing exceptional service to our publishers. Forget about waiting on tickets – your personal manager is fully dedicated to ensuring a seamless experience from start to finish.</p>
                        </div>
                    </div>

                    <h3>Mainstream Traffic</h3>
                    <div className="advantage-block">
                        <div className="left-side">
                            <p>Our platform targets popular mainstream verticals such as dating, software, and gambling. With Com2Cloud, you can be sure that your site will feature targeted ads from reputable advertisers, helping you to drive significant revenue.</p>
                        </div>
                        <div className="right-side">
                            <img src={trafficImage} alt="Mainstream traffic" />
                        </div>
                    </div>

                    <h3>Adult Traffic</h3>
                    <div className="advantage-block">
                        <div className="left-side">
                            <img src={trafficImage} alt="Adult traffic" />
                        </div>
                        <div className="right-side">
                            <p>We offer top-quality ads from premium and exclusive advertisers in the adult industry, ensuring that you can monetize your site with the most relevant and high-paying offers available.</p>
                        </div>
                    </div>

                    <h3>One Tag Solution</h3>
                    <div className="advantage-block">
                        <div className="left-side">
                            <img src={oneTagImage} alt="One tag solution" />
                        </div>
                        <div className="right-side">
                            <p>With just one tag, you gain full control over ad formats from our panel. It's the simplest way to manage and optimize your advertising strategy, all from a single, user-friendly interface.</p>
                        </div>
                    </div>

                    <p>Your ad revenue is just a few steps away from maximization. Our experienced managers are here to ensure your success every step of the way.</p>
                    <p>For inquiries and setup assistance, please send an email to <a href="mailto:publishers@com2cloud.com">publishers@com2cloud.com</a>.</p>
                </div>
            </div>
        </div>
    );
}

export default Publishers;
