import React from "react";
import "./RoundedButton.css";

function RoundedButton(props) {
  return (
    <>
      <div className="rounded-button">{props.children}</div>
    </>
  );
}

export default RoundedButton;
