import React from "react";
import "./Footer.css";
import githubIcon from "../media/white_socials/github.svg";
import facebookIcon from "../media/white_socials/facebook.svg";
import twitterIcon from "../media/white_socials/twitter.svg";
import linkedinIcon from "../media/white_socials/linkedin.svg";
import logoWhite from "../media/logo_white.svg";
import RoundedButton from "../RoundedButton/RoundedButton";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="logo-block">
          <img alt="com2cloud logo" src={logoWhite} />
          <div className="socials">
            <a href="https://github.com/com2cloud">
              <img alt="GitHub Icon" src={githubIcon} />
            </a>
            <a href="https://facebook.com/">
              <img alt="Facebook Icon" src={facebookIcon} />
            </a>
            <a href="https://twitter.com/com2cloud">
              <img alt="Twitter Icon" src={twitterIcon} />
            </a>
            <a href="https://www.linkedin.com/company/com2cloud/">
              <img alt="LinkedIn Icon" src={linkedinIcon} />
            </a>
          </div>
        </div>
        <div className="explore-block">
          <h2>Explore</h2>
          <div className="links">
            <a href="/">About Us</a>
            <a href="services">Services</a>
            <a href="products">Our Products</a>
            <a href="blog">Blog</a>
          </div>
        </div>
        <div className="contact-block">
          <h2>Contacts</h2>
          <a href="mailto:sales@com2cloud.com">sales@com2cloud.com</a>
          <a href="#contact">
            <RoundedButton>Contact Us</RoundedButton>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
