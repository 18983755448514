import React from "react";
import FirstAnimBlock from "./HomeContent/FirstAnimBlock";
import WhatWeDo from "./HomeContent/WhatWeDo";
import Services from "./HomeContent/Services";
import Partners from "./HomeContent/Partners";
import ContactUs from "./HomeContent/ContactUs";
import Blog from "./HomeContent/Blog";

import "./Home.css";

function Home(props) {
  return (
    <div className="landing">
      <div className="landing-content">
        <FirstAnimBlock />
        <WhatWeDo />
        <Services />
        <Partners />
        <Blog />
        <ContactUs />
      </div>
    </div>
  );
}

export default Home;
