import React from "react";
import "./Header.scss";
import logo from "../media/logo.svg";
import RoundedButton from "../RoundedButton/RoundedButton";

function Header(props) {
  return (
    <header className="header">
      <div className="header-content">
        <div className="header-section-1">
          <div className="logo">
            <img alt="logo" src={logo} />
          </div>
          <nav className="navigation">
            <a href="/">
              <div>About Us</div>
            </a>

            <a href="#services">
              <div>Services</div>
            </a>

            <a href="advertisers">
              <div>Advertisers</div>
            </a>

            <a href="publishers">
              <div>Publishers</div>
            </a>

            <a href="blog">
              <div>Blog</div>
            </a>
          </nav>
        </div>
        <div className="header-section-2">
          <div className="language">
            <a href="/#en">EN</a>
            <a href="/#ua">UA</a>
          </div>
          <div className="contact">
            <a href="#contact">
              <RoundedButton>Contact Us</RoundedButton>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
