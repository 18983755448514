import React from "react";
import "./WhatWeDo.css";
import Typewriter from "typewriter-effect";

new Typewriter("#typewriter", {
  strings: ["Hello", "World"],
  autoStart: true,
});

function WhatWeDo(props) {
  return (
    <div className="what-we-do">
      <div className="first">
        <h1>What We Do</h1>
      </div>
      <div className="second">
        <Typewriter
          options={{
            strings: [
              "Supporting your business",
              "Promoting your products",
              "Creating UI/UX designs",
              "Solving your problems",
              "Developing applications",
            ],
            autoStart: true,
            loop: true,
            delay: 50,
            skipAddStyles: true,
          }}
        />
        <div className="text">
          We are full-cycle developing solutions for businesses based on AWS
          cloud services and already made from concept to service 10+ projects
          from different fields, supporting in affiliate marketing, advertising
        </div>
      </div>
    </div>
  );
}

export default WhatWeDo;
