import React, { Component } from "react";
import "./ServiceItems.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const services_list = [
  {
    name: "Cloud-Based Software Development",
    description:
      "We specialize in developing robust and scalable cloud-based software solutions tailored to your business needs. Our cloud development services leverage cutting-edge technologies to ensure seamless performance and flexibility.",
  },
  {
    name: "Affiliate Marketing",
    description:
      "Our affiliate marketing services help businesses expand their online presence and drive revenue growth through strategic partnerships and affiliate networks. We'll optimize your affiliate campaigns for maximum results.",
  },
  {
    name: "Advertising and Promotion",
    description:
      "Our advertising and promotion experts create data-driven marketing strategies to boost your brand's visibility and drive customer engagement. We'll help you reach your target audience effectively.",
  },
  {
    name: "Full-Cycle Web Development",
    description:
      "From concept to deployment, our full-cycle web development services cover every aspect of web application development. We create responsive and user-friendly websites that enhance your online presence.",
  },
  {
    name: "UI/UX Design",
    description:
      "Our UI/UX design team specializes in creating intuitive and visually appealing user interfaces. We focus on enhancing user experiences, improving usability, and increasing customer satisfaction.",
  },
  {
    name: "Cloud Architecture",
    description:
      "Our cloud architecture experts design and implement scalable and secure cloud infrastructures for your business. We ensure your applications run smoothly in cloud environments.",
  },
  {
    name: "Data and Business Analysis",
    description:
      "Our data and business analysis services provide valuable insights to help you make informed decisions. We use data-driven approaches to optimize processes and drive business growth.",
  },
  {
    name: "DevOps",
    description:
      "Our DevOps services streamline development and operations processes, enabling faster software delivery and increased efficiency. We implement best practices for continuous integration and deployment.",
  },
];

let perChunk = 4;

let service_list_chunks = services_list.reduce((all, one, i) => {
  const ch = Math.floor(i / perChunk);
  all[ch] = [].concat(all[ch] || [], one);
  return all;
}, []);

export default class ServiceItems extends Component {
  render() {
    return (
      <>
        <OwlCarousel items={1} loop dots>
          {service_list_chunks.map((service_list_chunk) => (
            <div className="item services-items-block">
              {service_list_chunk.map((j, idx) => (
                <div className="item services-item" key={idx}>
                  <div className="name">{j.name}</div>
                  <div className="description">{j.description}</div>
                </div>
              ))}
            </div>
          ))}
        </OwlCarousel>
      </>
    );
  }
}
